body {
	font-family: "Open Sans", sans-serif;
	font-size: 17px;
	line-height: 1.5em;
	margin: 0;
	padding: 0;
	letter-spacing: 1px;
}
#showcase-gallery {
	background: #333;
	padding: 20px;
	margin: auto;
}
#showcase-gallery img {
	max-width: 800px;
}

.container {
	margin: auto;
}

/* COMMON CLASSES*/
.primary-text {
	color: #56a79a;
	border-bottom: 3px solid
}

.primary-background {
	background: #56a79a;
}

h4 svg {
	color: #000;
}
.card {
	margin:5px 0;
}
.icon-button {
	border-radius: 60px;
	box-shadow: 0px 0px 2px #888;
	padding: 0.5em 0.6em;
}

/* NAVBAR */
#navbar {
	text-transform: uppercase;
	color: #56a79a;
}

#navbar li.current a, #navbar a:hover {
	color: #56a79a;
}

/* SHOWCASE, SERVICES MARGINS */
#showcase-gallery {
	margin-top: 55px;
}

#services-list {
	margin-top: 100px;
}
/* FEATURES */
#features h2 {
	font-size: 35px;
	line-height: 1.6;
	margin: 0;
	padding-bottom: 10px;
}

#features .fa {
	font-size: 60px;
	color: #56a79a;
}

#features p {
	color: #888;
	margin: 15px 0 15px 0;
}
/* GALLERY */
.gallery-container {
	background: #333;
	padding: 20px;
}
.gallery-container h2 {
	color: #ddd;
}
.img-container {
	margin-top: 55px;
	background-color: #333;
	padding: 20px;
}
.img-container h2 {
	color: #fff;
	margin-bottom:35px;
	padding-top: 10px;
}
.img-list {
	/* text-align: center; */
	display: block;
	min-height: 1px;
	width: 100%;
	/* border: 1px solid #ddd; */
	overflow: auto;
	margin: 0 auto;
}

.ReactGridGallery_tile-viewport{
	border: 3px solid #fff;
	padding: 5px;
	border-radius: 5px;
}
#about-jumbo {
	margin-top: 55px;
	margin-bottom: 0;
}
/* FOOTER */
#main-footer {
	padding-top: 30px;
	background: #333;
	color: #fff;
}
.footer-copyright {
	margin-top: 14px;
    background: #56a79a;
}

#main-footer svg {
	margin-right: 5px;
}

/* PAGE */
.page {
	margin-bottom: 40px;
}
/* MEDIA QUERIES */
@media(max-width: 800px){
	#showcase h1 {
		font-size: 30px;
	}
}

/* CONTACT */
form {
	margin: 100px 20px 20px 20px;
}
form input{
	width: 50%;
	height: 30px;
	margin-bottom: 10px;
}

form textarea{
	width: 50%;
	height: 80px;
}

form button{
	width: 40%;
	padding: 10px;
}

@media(max-width: 500px){
	#showcase .showcase-content {
		padding:  40px 10px;
	}
}